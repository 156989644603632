import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IDeliveryOrder,
  IDeliveryOrderItemList,
} from "../../../types/Logistic/deliveryOrder";
import { useForm, useWatch } from "react-hook-form";
import {
  deliveryOrderSchema,
  deliveryOrderValidation,
} from "../../../components/Form/Logistic/DeliveryOrder/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomerInfoForm from "../../../components/Form/CustomerInfo/CustomerInfoForm";
import { EntityTypeEnum } from "../../../generated/creatable";
import DeliveryOrderHeader from "../../../components/Form/Logistic/DeliveryOrder/Header";
import PriceVatType from "../../../components/Form/Logistic/PriceVatType";
import LogisticFooter from "../../../components/Form/Logistic/Footer";
import DeliveryOrderItemList from "../../../components/Table/Logistic/ItemList/DeliveryOrderItemList";
import {
  DeliveryDocumentType,
  DeliveryOrderCreateInput,
  DeliveryOrderQuery,
  DeliveryOrderUpdateInput,
  useDeliveryOrderCreateMutation,
  useDeliveryOrderQuery,
  useDeliveryOrderUpdateMutation,
  useLogisticDocumentNextStatusMutation,
  useLogisticDocumentSetStepMutation,
} from "../../../generated/logistic";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import {
  deliveryOrderCreatePayloadFormatter,
  deliveryOrderQueryFormatter,
  deliveryOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/DeliveryOrder";
import { Box, CircularProgress } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useDeliveryError } from "../../../hooks/Logistic/use-delivery-error";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [referenceItemList, setReferenceItemList] = useState<
    IDeliveryOrderItemList[]
  >([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    resetField,
  } = useForm<IDeliveryOrder>({
    defaultValues: deliveryOrderSchema,
    resolver: yupResolver(deliveryOrderValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const { data, isLoading, isSuccess, refetch } =
    useDeliveryOrderQuery<DeliveryOrderQuery>(
      graphQLClientWithHeaderLogistic,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutate: create } =
    useDeliveryOrderCreateMutation<Error>(graphQLClientWithHeaderLogistic, {
      onSuccess: ({ deliveryOrderCreate }) => {
        if (deliveryOrderCreate) {
          if (deliveryOrderCreate.sub_status === "wait_deliver") {
            updateStatus({
              documentInput: {
                reference_document_type: DeliveryDocumentType.DeliveryOrder,
                unique_id: deliveryOrderCreate.unique_id,
              },
            });
          }
          navigate(
            `/logistic/delivery_order/${deliveryOrderCreate?.unique_id}`
          );
          enqueueSnackbar("สร้างใบส่งของสำเร็จ", {
            variant: "success",
          });
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบส่งของไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutate: update } =
    useDeliveryOrderUpdateMutation<Error>(graphQLClientWithHeaderLogistic, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}ใบส่งของสำเร็จ`, {
          variant: "success",
        });
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`${snackbarMessage}ใบส่งของไม่สำเร็จ`, {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isChanging, mutate: updateStatus } =
    useLogisticDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderLogistic,
      {
        onSuccess: () => {
          if (id) {
            refetch();
          }
        },
      }
    );

  const { mutate: setStep } = useLogisticDocumentSetStepMutation<Error>(
    graphQLClientWithHeaderLogistic,
    {
      onSuccess: () => {
        if (id) {
          refetch();
        }
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const { deliveryOrder } = data;

      const getDeliveryOrderData = async () => {
        const deliveryOrderType = deliveryOrder as IDeliveryOrder;
        const formattedDeliveryOrder = await deliveryOrderQueryFormatter(
          deliveryOrderType
        );
        reset(formattedDeliveryOrder);
      };
      getDeliveryOrderData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      reset(state);
      setReferenceItemList(state.item_list);
    }
  }, [reset, state]);

  useEffect(() => {
    if (!isLoading && id && data?.deliveryOrder?.aggrid_status !== "draft") {
      setDisabled(true);
    }
  }, [data?.deliveryOrder?.aggrid_status, id, isLoading]);

  const onDeliveryOrderCreate = (data: IDeliveryOrder, status: string) => {
    if (data) {
      const payload = deliveryOrderCreatePayloadFormatter(
        data,
        status
      ) as DeliveryOrderCreateInput;
      create({
        createInput: payload,
      });
    }
  };

  const onDeliveryOrderUpdate = async (
    data: IDeliveryOrder,
    status: string
  ) => {
    if (data) {
      const payload = (await deliveryOrderUpdatePayloadFormatter(
        data,
        status
      )) as DeliveryOrderUpdateInput;
      setSnackbarMessage("แก้ไข");
      update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onDeliveryOrderWaitDeliver = async (data: IDeliveryOrder) => {
    if (data) {
      if (!id) {
        onDeliveryOrderCreate(data, "wait_deliver");
      } else {
        const payload = (await deliveryOrderUpdatePayloadFormatter(
          data,
          "wait_deliver"
        )) as DeliveryOrderUpdateInput;
        setSnackbarMessage("ยืนยัน");
        update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        updateStatus({
          documentInput: {
            reference_document_type: DeliveryDocumentType.DeliveryOrder,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onDeliveryOrderSubmitDetail = async (data: IDeliveryOrder) => {
    if (data) {
      const { sub_status, ...newData } = data;
      const payload = (await deliveryOrderUpdatePayloadFormatter(
        newData,
        sub_status || ""
      )) as DeliveryOrderUpdateInput;
      let step;
      if (sub_status === "completed") step = 4;
      else if (sub_status === "not_completed") step = 3;
      update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      setStep({
        documentSetStepInput: {
          reference_document_type: DeliveryDocumentType.DeliveryOrder,
          unique_id: newData.unique_id,
          step: step || 4,
        },
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onDeliveryOrderEditHandler = async (data: IDeliveryOrder) => {
    setDisabled(true);
    setIsEdit(false);
    setSnackbarMessage("แก้ไข");
    await onDeliveryOrderUpdate(data, data.sub_status ? data.sub_status : "");
  };

  useDeliveryError(errors);

  const renderButton = () => {
    switch (data?.deliveryOrder?.aggrid_status) {
      case "draft":
        return (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onDeliveryOrderUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={handleSubmit(onDeliveryOrderWaitDeliver)}
              disabled={isChanging}
            />
          </Box>
        );
      case "wait_deliver":
      case "cancelled":
      case "completed":
      case "not_completed":
        if (isEdit) {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onDeliveryOrderEditHandler)}
              />
            </Box>
          );
        }
        return;
      default:
        return (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onDeliveryOrderCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.confirm")}
              variant="contained"
              onClick={handleSubmit(onDeliveryOrderWaitDeliver)}
            />
          </Box>
        );
    }
  };

  if (id && (isLoading || isUpdating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <DeliveryOrderHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        refetch={refetch}
        editClickHandler={editClickHandler}
        handleSubmit={handleSubmit}
        onDeliveryOrderSubmitDetail={onDeliveryOrderSubmitDetail}
      />
      <CustomerInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.DeliveryOrder}
      />
      <PriceVatType control={control} errors={errors} disabled={disabled} />
      <DeliveryOrderItemList
        documentType={EntityTypeEnum.DeliveryOrder}
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        getValues={getValues}
        referenceItemList={referenceItemList}
      />
      <LogisticFooter
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        documentType="delivery_order"
        resetField={resetField}
      />
      {renderButton()}
    </form>
  );
};

export default DocumentInfoTab;
