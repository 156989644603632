import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { useParams } from "react-router-dom";
import { useQuotationQuery, QuotationQuery } from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import QTPdfLayout from "../../../components/UI/SalesPdf/QuotationPdf/PDFLayout";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { useReactToPrint } from "react-to-print";
import { useRef, ReactInstance } from "react";
import PDFTable from "../../../components/Table/Pdf/PDFTable";
import { formatDate } from "../../../utils/Formatter/Date";
import { formatTaxId } from "../../../utils/Formatter/Global";

export const renderAddressString = (addressValues: any) => {
  if (addressValues) {
    const { address, sub_district, district, province, postal_code, country } =
      addressValues;

    const orderedValues = {
      address,
      sub_district,
      district,
      province,
      postal_code,
      country,
    };
    let result = "";

    if (addressValues && orderedValues) {
      if (Array.isArray(addressValues)) {
        addressValues.forEach((item) => {
          if (item?.length > 0) {
            result = result + item + " ";
          }
        });
      } else {
        const keys = Object.keys(orderedValues);
        keys.forEach((key) => {
          const newValue = orderedValues[key as keyof typeof orderedValues];
          if (newValue && newValue.length > 0) {
            result = result + newValue + " ";
          }
        });
      }
      if (result?.trim().length === 0) {
        return "-";
      }
    }
    return result?.trim();
  } else return "";
};

const QuotationPDF = () => {
  const { id } = useParams();
  const documentType = "quotation";

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } = useQuotationQuery<QuotationQuery>(
    graphQLClientWithHeaderSales,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.quotation,
    295,
    790,
    480,
    213,
    169
  );

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const leftHeader = [
    "ลูกค้า",
    "ที่อยู่",
    "เลขประจำตัวผู้เสียภาษี",
    "เบอร์โทรศัพท์",
    "โทรสาร",
    "อีเมล",
  ];

  const leftInfo = [
    data?.quotation?.customer_contact_unique_id +
      " - " +
      data?.quotation?.customer_contact?.name,
    renderAddressString(
      data?.quotation?.customer_contact?.billing_address || {}
    ) || "-",
    formatTaxId(
      data?.quotation?.customer_contact?.identity_no || "",
      data?.quotation?.customer_contact?.branch || ""
    ) || "-",
    data?.quotation?.customer_contact?.phone || "-",
    data?.quotation?.customer_contact?.fax || "-",
    data?.quotation?.customer_contact?.email || "-",
  ];

  const highlightHeader = ["วันที่ออกเอกสาร", "ใช้ได้ถึง", "เครดิต"];

  const highlightInfo = [
    data?.quotation?.issue_date ? formatDate(data?.quotation?.issue_date) : "-",
    data?.quotation?.due_date ? formatDate(data?.quotation?.due_date) : "-",
    data?.quotation?.credit_day ? data?.quotation?.credit_day + " วัน" : "-",
  ];

  const rightHeader = ["พนักงานขาย"];

  const rightInfo = [
    data?.quotation?.sales_contact_list &&
    data?.quotation?.sales_contact_list.length > 0
      ? data?.quotation?.sales_contact_list
          .map((contact) => `${contact.first_name} ${contact.last_name}`)
          .join(", ")
      : "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบเสนอราคา/Quotation</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <QTPdfLayout
            documentName="ใบเสนอราคา/Quotation"
            data={data?.quotation}
            documentType={documentType}
            page={index + 1}
            allPage={paginatedItems.length}
            footer={index === paginatedItems.length - 1}
            leftHeader={leftHeader}
            leftInfo={leftInfo}
            highlightHeader={highlightHeader}
            highlightInfo={highlightInfo}
            rightHeader={rightHeader}
            rightInfo={rightInfo}
          >
            <PDFTable data={item} start_no={startNumbers[index]} />
          </QTPdfLayout>
        ))}
      </Box>
    </>
  );
};

export default QuotationPDF;
