import { useParams } from "react-router-dom";
import { SalesOrderQuery, useSalesOrderQuery } from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { formatDate } from "../../../utils/Formatter/Date";
import SOPdfLayout from "../../../components/UI/SalesPdf/SalesOrderPdf/PDFLayout";
import { ReactInstance, useRef } from "react";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { useReactToPrint } from "react-to-print";
import { renderAddressString } from "../Quotation/PDF";
import PDFTable from "../../../components/Table/Pdf/PDFTable";
import { formatTaxId } from "../../../utils/Formatter/Global";

const SalesOrderPDF = () => {
  const { id } = useParams();
  const documentType = "sales_order";

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } = useSalesOrderQuery<SalesOrderQuery>(
    graphQLClientWithHeaderSales,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.salesOrder,
    295,
    790,
    480,
    213,
    237
  );

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const leftHeader = [
    "ลูกค้า",
    "ที่อยู่",
    "เลขประจำตัวผู้เสียภาษี",
    "เบอร์โทรศัพท์",
    "โทรสาร",
    "อีเมล",
  ];

  const leftInfo = [
    data?.salesOrder?.customer_contact_unique_id +
      " - " +
      data?.salesOrder?.customer_contact?.name,
    renderAddressString(
      data?.salesOrder?.customer_contact?.billing_address || {}
    ) || "-",
    formatTaxId(
      data?.salesOrder?.customer_contact?.identity_no || "",
      data?.salesOrder?.customer_contact?.branch || ""
    ) || "-",
    data?.salesOrder?.customer_contact?.phone || "-",
    data?.salesOrder?.customer_contact?.fax || "-",
    data?.salesOrder?.customer_contact?.email || "-",
  ];

  const highlightHeader = [
    "วันที่ออกเอกสาร",
    "ใช้ได้ถึง",
    "วันกำหนดส่งของ",
    "เครดิต",
  ];

  const highlightInfo = [
    data?.salesOrder?.issue_date
      ? formatDate(data?.salesOrder?.issue_date)
      : "-",
    data?.salesOrder?.due_date ? formatDate(data?.salesOrder?.due_date) : "-",
    data?.salesOrder?.delivery_date
      ? formatDate(data?.salesOrder?.delivery_date)
      : "-",
    data?.salesOrder?.credit_day ? data?.salesOrder?.credit_day + " วัน" : "-",
  ];

  const rightHeader = [
    "หมายเลขอ้างอิง",
    "อ้างอิงถึง",
    "ประเภทรับชำระ",
    "พนักงานขาย",
  ];

  const rightInfo = [
    data?.salesOrder?.external_reference_id
      ? data?.salesOrder.external_reference_id
      : "-",
    data?.salesOrder?.reference_unique_id_list &&
    data?.salesOrder?.reference_unique_id_list.length > 0
      ? data?.salesOrder?.reference_unique_id_list.join(", ")
      : "-",
    data?.salesOrder?.payment_type || "-",
    data?.salesOrder?.sales_contact_list &&
    data?.salesOrder?.sales_contact_list.length > 0
      ? data?.salesOrder?.sales_contact_list
          .map((contact) => `${contact.first_name} ${contact.last_name}`)
          .join(", ")
      : "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบสั่งขาย/Sales Order</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <SOPdfLayout
            documentName="ใบสั่งขาย/Sales Order"
            data={data?.salesOrder}
            documentType={documentType}
            footer={index === paginatedItems.length - 1}
            page={index + 1}
            allPage={paginatedItems.length}
            leftHeader={leftHeader}
            leftInfo={leftInfo}
            highlightHeader={highlightHeader}
            highlightInfo={highlightInfo}
            rightHeader={rightHeader}
            rightInfo={rightInfo}
          >
            <PDFTable data={item} start_no={startNumbers[index]} />
          </SOPdfLayout>
        ))}
      </Box>
    </>
  );
};

export default SalesOrderPDF;
