import { Fragment, useRef } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { Link, Typography } from "@mui/material";
import AgGrid from "../../../components/UI/AgGrid";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { TRACE_ENTRY_FIND_MANY_AGGRID } from "../../../services/AgGrid/InventoryAgGrid";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../utils/Formatter/AgGridFilter";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideDatasource,
  ValueFormatterParams,
} from "ag-grid-community";
import { formatDate } from "../../../utils/Formatter/Date";
import { ITraceEntry } from "../../../types/Inventory";
import { IAvatar } from "../../../types/global";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import {
  entryTypeEngToThai,
  formatInventoryDocumentType,
} from "../../../utils/dataTransformer";
import { GraphQLClient } from "graphql-request";

const TransactionTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const gridRef: any = useRef();

  const navigateToGoodPage = (type: string, unique_id: string) => {
    window.open(
      `/inventory/${type}/${encodeURIComponent(unique_id)}`,
      "_blank"
    );
  };

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        posted_date,
        lot_date,
        source_bin_location,
        source_warehouse_name,
        source_warehouse_unique_id,
        destination_bin_location,
        destination_warehouse_name,
        destination_warehouse_unique_id,
        is_scanned,
        is_active,
        uom_name,
        scanned_by,
        ...otherFilter
      } = filterModel;

      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        lot_date: dateFilterModel(lot_date),
        item_unique_id: {
          filterType: "text",
          type: "equals",
          filter: id,
        },
        is_approve: { filterType: "boolean", type: "equals", filter: true },
        status: {
          filterType: "set",
          values: ["is_scan"],
        },
        source_bin_location: (source_warehouse_name ||
          source_warehouse_unique_id ||
          source_bin_location) && {
          filterType: "object",
          filter: {
            sub_level_3: (source_warehouse_name ||
              source_warehouse_unique_id) && {
              filterType: "object",
              filter: {
                sub_level_2: {
                  filterType: "object",
                  filter: {
                    sub_level_1: {
                      filterType: "object",
                      filter: {
                        warehouse: {
                          filterType: "object",
                          filter: source_warehouse_unique_id
                            ? {
                                unique_id: source_warehouse_unique_id,
                              }
                            : source_warehouse_name
                            ? {
                                name: source_warehouse_name,
                              }
                            : {},
                        },
                      },
                    },
                  },
                },
              },
            },
            bin_name: source_bin_location,
          },
        },
        destination_bin_location:
          destination_warehouse_name ||
          destination_warehouse_unique_id ||
          (destination_bin_location && {
            filterType: "object",
            filter: {
              sub_level_3: (destination_warehouse_name ||
                destination_warehouse_unique_id) && {
                filterType: "object",
                filter: {
                  sub_level_2: {
                    filterType: "object",
                    filter: {
                      sub_level_1: {
                        filterType: "object",
                        filter: {
                          warehouse: {
                            filterType: "object",
                            filter: destination_warehouse_unique_id
                              ? {
                                  unique_id: destination_warehouse_unique_id,
                                }
                              : destination_warehouse_name
                              ? {
                                  name: destination_warehouse_name,
                                }
                              : {},
                          },
                        },
                      },
                    },
                  },
                },
              },
              bin_name: destination_bin_location,
            },
          }),
        scanned_by: scanned_by && {
          filterType: "json",
          type: scanned_by.type,
          filter: scanned_by.filter,
          path: ["first_name"],
        },
        uom: uom_name && {
          fitlerType: "object",
          filter: {
            name: uom_name,
          },
        },
      };

      const sortbyId = [...sortModel, { colId: "created_date", sort: "asc" }];

      try {
        const { traceEntriesFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(
            TRACE_ENTRY_FIND_MANY_AGGRID,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel: sortbyId,
              },
            }
          );

        params.success({
          rowData: traceEntriesFindManyAggrid.data as any[],
          rowCount: traceEntriesFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("inventory.list.posted_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "source_warehouse_unique_id",
      headerName: t("inventory.list.sourceWarehousDocId"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.source_bin_location)
          return params.data.source_bin_location.sub_level_3.sub_level_2
            .sub_level_1.warehouse.unique_id;
        else return "";
      },
    },
    {
      field: "source_warehouse_name",
      headerName: t("inventory.list.sourceWarehouse"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.source_bin_location)
          return params.data.source_bin_location.sub_level_3.sub_level_2
            .sub_level_1.warehouse.name;
        else return "";
      },
    },
    {
      field: "source_bin_location.bin_name",
      headerName: t("inventory.list.sourceBinLocation"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value.bin_name;
        else return "";
      },
    },
    {
      field: "destination_warehouse_unique_id",
      headerName: t("inventory.list.destinationWarehouseDocId"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.destination_bin_location)
          return params.data.destination_bin_location.sub_level_3.sub_level_2
            .sub_level_1.warehouse.unique_id;
        else return "";
      },
    },
    {
      field: "destination_warehouse_name",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.destination_bin_location)
          return params.data.destination_bin_location.sub_level_3.sub_level_2
            .sub_level_1.warehouse.name;
        else return "";
      },
    },
    {
      field: "destination_bin_location",
      headerName: t("inventory.list.destinationBinLocation"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return params.value.bin_name;
        else return "";
      },
    },
    {
      field: "item_unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "lot_date",
      headerName: t("inventory.list.lot_number_thai"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "serial_no",
      headerName: t("inventory.serialNumber"),
      filter: false,
      sortable: false,
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: false,
      sortable: false,
    },
    {
      field: "qty",
      headerName: t("inventory.list.qty"),
      filter: false,
      sortable: false,
    },
    {
      field: "uom_name",
      headerName: t("inventory.unit"),
      filter: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.uom) return params.data.uom.name;
        else return "";
      },
    },
    {
      field: "type",
      headerName: t("inventory.stockEntry.entryDocumentType"),
      filter: false,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return entryTypeEngToThai(params.value);
        else return "";
      },
    },
    {
      field: "reference_unique_id",
      headerName: t("inventory.stockEntry.referenceDocument"),
      filter: false,
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => {
        const { reference_unique_id, reference_document_type } =
          formatInventoryDocumentType(params.data);
        return (
          <Link
            onClick={() =>
              navigateToGoodPage(reference_document_type, reference_unique_id)
            }
            style={{ cursor: "pointer" }}
          >
            {reference_unique_id}
          </Link>
        );
      },
    },
    {
      field: "scanned_by",
      headerName: t("inventory.items.scanned_by"),
      filter: false,
      cellRenderer: (params: ICellRendererParams<ITraceEntry, IAvatar>) => {
        if (params.value) {
          if (params.value.img_url && params.value.img_url.length > 0) {
            return <CustomizedAvatar avatars={[params.value]} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.value.first_name + " " + params.value.last_name}
              />
            );
          }
        } else return <></>;
      },
    },
  ];

  return (
    <Fragment>
      <CustomizedBox>
        <Typography fontWeight={"bold"} mb={3}>
          {t("inventory.items.transaction")}
        </Typography>
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          disabledSidebar
          height={450}
        />
      </CustomizedBox>
    </Fragment>
  );
};

export default TransactionTab;
