import { useParams } from "react-router-dom";
import {
  PurchaseOrderQuery,
  usePurchaseOrderQuery,
} from "../../../generated/purchase";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { formatDate } from "../../../utils/Formatter/Date";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { renderAddressString } from "../../Sales/Quotation/PDF";
import { useRef, ReactInstance } from "react";
import { useReactToPrint } from "react-to-print";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import POPdfLayout from "../../../components/UI/Pdf/PurchasePdf/PurchaseOrder/PDFLayout";
import PDFTable from "../../../components/Table/Pdf/PDFTable";
import { formatTaxId } from "../../../utils/Formatter/Global";

const PurchaseOrderPDF = () => {
  const { id } = useParams();
  const documentType = "purchase_order";

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const { data, isLoading } = usePurchaseOrderQuery<PurchaseOrderQuery>(
    graphQLClientWithHeaderPurchase,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.purchaseOrder,
    295, // item cell width in table
    790, // max height for table without footer
    480, // max height for table with footer
    213, // minimum height of left header
    173 // minimum height of right header
  );

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const leftHeader = [
    "ผู้ขาย",
    "ที่อยู่",
    "เลขประจำตัวผู้เสียภาษี",
    "เบอร์โทรศัพท์",
    "โทรสาร",
    "อีเมล",
  ];

  const leftInfo = [
    data?.purchaseOrder?.vendor_contact_unique_id +
      " - " +
      data?.purchaseOrder?.vendor_contact?.name,
    renderAddressString(
      data?.purchaseOrder?.vendor_contact?.billing_address || {}
    ) || "-",
    formatTaxId(
      data?.purchaseOrder?.vendor_contact?.identity_no || "",
      data?.purchaseOrder?.vendor_contact?.branch || ""
    ) || "-",
    data?.purchaseOrder?.vendor_contact?.phone || "-",
    data?.purchaseOrder?.vendor_contact?.fax || "-",
    data?.purchaseOrder?.vendor_contact?.email || "-",
  ];

  const highlightHeader = ["วันที่ออกเอกสาร", "เครดิต"];

  const highlightInfo = [
    data?.purchaseOrder?.issue_date
      ? formatDate(data?.purchaseOrder?.issue_date)
      : "-",
    data?.purchaseOrder?.credit_day
      ? data?.purchaseOrder?.credit_day + " วัน"
      : "-",
  ];

  const rightHeader = ["อ้างอิงถึง", "หมายเลขอ้างอิง"];

  const rightInfo = [
    data?.purchaseOrder?.reference_unique_id_list &&
    data?.purchaseOrder?.reference_unique_id_list.length > 0
      ? data?.purchaseOrder?.reference_unique_id_list.join(", ")
      : "-",
    data?.purchaseOrder?.external_reference_id
      ? data?.purchaseOrder.external_reference_id
      : "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">ใบสั่งซื้อ/Purchase Order</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>
      <Box ref={componentRef} sx={{ m: "auto" }}>
        {paginatedItems.map((item, index) => (
          <POPdfLayout
            documentName="ใบสั่งซื้อ/Purchase Order"
            data={data?.purchaseOrder}
            documentType={documentType}
            footer={index === paginatedItems.length - 1}
            page={index + 1}
            allPage={paginatedItems.length}
            leftHeader={leftHeader}
            leftInfo={leftInfo}
            highlightHeader={highlightHeader}
            highlightInfo={highlightInfo}
            rightHeader={rightHeader}
            rightInfo={rightInfo}
          >
            <PDFTable data={item} start_no={startNumbers[index]} isPurchase />
          </POPdfLayout>
        ))}
      </Box>
    </>
  );
};

export default PurchaseOrderPDF;
