import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import AddressForm from "../Address";
import { IDefaultForm } from "../../../types/global";

const CompanyAddressForm = ({
  control,
  disabled,
  errors,
  getValues,
  setValue,
  handleSubmit,
  reset,
}: IDefaultForm) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ mb: 3 }}>
      <Accordion
        sx={{ borderRadius: "15px", boxShadow: "0px 2px 7px #E5E5E5" }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography ml={1} fontWeight="bold">
            {t("address.index")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddressForm
            control={control}
            errors={errors}
            disabled={disabled}
            getValues={getValues}
            setValue={setValue}
            handleSubmit={handleSubmit}
            reset={reset}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CompanyAddressForm;
