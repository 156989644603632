import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IRemarkTemplate } from "../../../types/Setting/remark";
import {
  remarkTemplateSchema,
  remarkTemplateValidation,
} from "../../../components/Form/RemarkTemplate/schema";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Typography } from "@mui/material";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RemarkTemplateForm from "../../../components/Form/RemarkTemplate";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRemarkTemplateUpsertMutation } from "../../../generated/remark";
import { GraphQLClient } from "graphql-request";

const ManufactureRemark = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState(
    `${pathname}?tab=manufacture_order`
  );
  const { enqueueSnackbar } = useSnackbar();

  const [removeIds, setRemoveIds] = useState<number[]>([]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.remark.index"),
      to: "/setting/remark",
    },
    {
      name: t("setting.remark.manufacture"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("manufacture.order.index"),
      path: `${pathname}?tab=manufacture_order`,
    },
  ];

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { mutate } = useRemarkTemplateUpsertMutation(
    graphQLClientWithHeaderSetting,
    {
      onSuccess() {
        enqueueSnackbar("เพิ่ม/แก้ไขหมายเหตุสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("เพิ่ม/แก้ไขหมายเหตุไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IRemarkTemplate>({
    defaultValues: remarkTemplateSchema,
    resolver: yupResolver(remarkTemplateValidation),
  });

  useEffect(() => {
    switch (tab) {
      case "manufacture_order":
        setCurrentTab(pathname + "?tab=manufacture_order");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const onRemarkTemplateSubmitHandler = (data: IRemarkTemplate) => {
    mutate({
      upsertManyInput: data.remark_templates,
      idsToDelete: removeIds,
    });
  };

  return (
    <form onSubmit={handleSubmit(onRemarkTemplateSubmitHandler)}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" my={2}>
        {t("setting.remark.manufacture")}
      </Typography>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      <RemarkTemplateForm
        control={control}
        errors={errors}
        tab={tab}
        setRemoveIds={setRemoveIds}
      />
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
      />
    </form>
  );
};

export default ManufactureRemark;
