import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IPurchaseRequest } from "../../../types/Purchase/purchaseRequest";
import { useForm, useWatch } from "react-hook-form";
import PurchaseRequestHeader from "../../../components/Form/Purchase/Request/Header";
import RequestorForm from "../../../components/Form/Purchase/Request/Requestor";
import PurchaseItemList from "../../../components/Table/Purchase/ItemList/PurchaseItemList";
import PurchaseFooter from "../../../components/Form/Purchase/Footer";
import {
  purchaseRequestSchema,
  purchaseRequestValidation,
} from "../../../components/Form/Purchase/Request/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress } from "@mui/material";
import {
  PurchaseDocumentType,
  PurchaseRequestCreateInput,
  PurchaseRequestQuery,
  PurchaseRequestUpdateInput,
  usePurchaseDocumentNextStatusMutation,
  usePurchaseRequestCreateMutation,
  usePurchaseRequestQuery,
  usePurchaseRequestUpdateMutation,
} from "../../../generated/purchase";
import {
  WarehousesQuery,
  useWarehousesQuery,
} from "../../../generated/inventory";
import { errorMessageFormatter } from "../../Setting/Inventory/Warehouse";
import { useSnackbar } from "notistack";
import {
  purchaseRequestCreatePayloadFormatter,
  purchaseRequestQueryFormatter,
  purchaseRequestUpdatePayloadFormatter,
} from "../../../utils/Formatter/PurchaseRequest";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { EntityTypeEnum } from "../../../generated/creatable";
import { usePurchaseError } from "../../../hooks/Purchase/use-purchase-error";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import dayjs from "dayjs";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<IPurchaseRequest>({
    defaultValues: purchaseRequestSchema,
    resolver: yupResolver(purchaseRequestValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseRequestQuery<PurchaseRequestQuery>(
      graphQLClientWithHeaderPurchase,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutate: create } =
    usePurchaseRequestCreateMutation<Error>(graphQLClientWithHeaderPurchase, {
      onSuccess: ({ purchaseRequestCreate }) => {
        if (purchaseRequestCreate) {
          if (purchaseRequestCreate.sub_status === "wait_approve") {
            updateStatus({
              documentInput: {
                reference_document_type: PurchaseDocumentType.PurchaseRequest,
                unique_id: purchaseRequestCreate.unique_id,
              },
            });
          }
          navigate(`/purchase/request/${purchaseRequestCreate?.unique_id}`);
          enqueueSnackbar("สร้างใบขอซื้อสำเร็จ", {
            variant: "success",
          });
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบขอซื้อไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutate: update } =
    usePurchaseRequestUpdateMutation<Error>(graphQLClientWithHeaderPurchase, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}ใบขอซื้อสำเร็จ`, {
          variant: "success",
        });
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`${snackbarMessage}ใบขอซื้อไม่สำเร็จ`, {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isChanging, mutate: updateStatus } =
    usePurchaseDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderPurchase,
      {
        onSuccess: () => {
          if (id) {
            refetch();
          }
        },
      }
    );

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  useEffect(() => {
    if (isSuccess) {
      const { purchaseRequest } = data;

      const getPurchaseRequestData = async () => {
        const purchaseRequestType = purchaseRequest as IPurchaseRequest;
        const formattedPurchaseRequest = await purchaseRequestQueryFormatter(
          purchaseRequestType
        );
        reset(formattedPurchaseRequest);
      };

      getPurchaseRequestData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      reset({ ...state, issue_date: dayjs(), due_date: dayjs() });
    }
  }, [reset, state]);

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.purchaseRequest?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.purchaseRequest?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onPurchaseRequestCreate = (data: IPurchaseRequest, status: string) => {
    if (data) {
      const payload = purchaseRequestCreatePayloadFormatter(
        data,
        status
      ) as PurchaseRequestCreateInput;
      create({
        createInput: payload,
      });
    }
  };

  const onPurchaseRequestUpdate = (data: IPurchaseRequest, status: string) => {
    if (data) {
      const payload = purchaseRequestUpdatePayloadFormatter(
        data,
        status
      ) as PurchaseRequestUpdateInput;
      setSnackbarMessage("แก้ไข");
      update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onPurchaseRequestSendApprove = (data: IPurchaseRequest) => {
    if (data) {
      const payload = purchaseRequestUpdatePayloadFormatter(
        data,
        "wait_approve"
      ) as PurchaseRequestUpdateInput;

      if (!id) {
        onPurchaseRequestCreate(data, "wait_approve");
      } else {
        setSnackbarMessage("ส่งอนุมัติ");
        update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        updateStatus({
          documentInput: {
            reference_document_type: PurchaseDocumentType.PurchaseRequest,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onPurchaseRequestNotApprove = (data: IPurchaseRequest) => {
    const payload = purchaseRequestUpdatePayloadFormatter(
      data,
      "wait_approve",
      true
    ) as PurchaseRequestUpdateInput;
    setSnackbarMessage("ไม่อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
  };

  const onPurchaseRequestApprove = (data: IPurchaseRequest) => {
    const payload = purchaseRequestUpdatePayloadFormatter(
      data,
      "approved"
    ) as PurchaseRequestUpdateInput;
    setSnackbarMessage("อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
    updateStatus({
      documentInput: {
        reference_document_type: PurchaseDocumentType.PurchaseRequest,
        unique_id: data.unique_id,
      },
    });
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onPurchaseRequestEditHandler = (data: IPurchaseRequest) => {
    setDisabled(true);
    setIsEdit(false);
    setSnackbarMessage("แก้ไข");
    onPurchaseRequestUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const renderButton = () => {
    switch (data?.purchaseRequest?.aggrid_status) {
      case "draft":
        return (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onPurchaseRequestUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseRequestSendApprove)}
              disabled={isChanging}
            />
          </Box>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseRequestEditHandler)}
              />
            </Box>
          );
        } else {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.not_approve")}
                disabled={isUpdating}
                onClick={handleSubmit(onPurchaseRequestNotApprove)}
              />
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                onClick={handleSubmit(onPurchaseRequestApprove)}
                disabled={isChanging}
              />
            </Box>
          );
        }

      case "not_approved":
      case "approved":
      case "cancelled":
      case "partially_ordered":
      case "fully_ordered":
        if (isEdit) {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseRequestEditHandler)}
              />
            </Box>
          );
        }
        return;
      default:
        return (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onPurchaseRequestCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseRequestSendApprove)}
            />
          </Box>
        );
    }
  };

  usePurchaseError(errors);

  if ((id && (isLoading || isUpdating)) || isWarehouseLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <PurchaseRequestHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        allWarehouses={warehouseData?.warehouses || []}
        editClickHandler={editClickHandler}
        refetch={refetch}
      />
      <RequestorForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
      />
      <PurchaseItemList
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        documentType={EntityTypeEnum.PurchaseRequest}
      />
      <PurchaseFooter
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        documentType="purchase_request"
      />
      {renderButton()}
    </form>
  );
};

export default DocumentInfoTab;
