import { useTranslation } from "react-i18next";

import { IDefaultForm } from "../../../../types/global";

import { Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import DocDropzoneUI from "../../../UI/DocDropzoneUI";

type Props = {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  setValue: IDefaultForm["setValue"];
  disabled: IDefaultForm["disabled"];
};

const BomAttachment = ({ control, setValue, errors, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <CustomizedBox>
      <Typography ml={1} mb={3} fontWeight="bold">
        {t("sentence.attachment_list")}
      </Typography>
      <DocDropzoneUI
        control={control}
        name="attachment_list"
        setValue={setValue}
        disabled={disabled}
        multiple
      />
    </CustomizedBox>
  );
};

export default BomAttachment;
