import { Fragment } from "react";
import { Divider } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { IUom } from "../../../types/Inventory/item";
import { IDefaultForm, ITab } from "../../../types/global";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedScrollbar from "../../../components/Custom/CustomizedScrollbar";

import MRP from "../../../components/Form/Inventory/Item/MRP";
import Sales from "../../../components/Form/Inventory/Item/Sales";
import Stock from "../../../components/Form/Inventory/Item/Stock";
import ItemImg from "../../../components/Form/Inventory/Item/ItemImg";
import General from "../../../components/Form/Inventory/Item/General";
import Purchase from "../../../components/Form/Inventory/Item/Purchase";
import Logistic from "../../../components/Form/Inventory/Item/Logistic";
import Warehouse from "../../../components/Form/Inventory/Item/Warehouse";
import Manufacture from "../../../components/Form/Inventory/Item/Manufacture";
import SpecificInfo from "../../../components/Form/Inventory/Item/SpecificInfo";
import Information from "../../../components/Form/Inventory/Information/Information";

type ExtendedProps = IDefaultForm & {
  isInventoryPage: boolean;
  getID?: () => void;
  allUoms: IUom[];
  hasBarcodeError?: boolean;
};

const DetailTab = ({
  control,
  errors,
  getValues,
  setValue,
  handleSubmit,
  allUoms,
  reset,
  isInventoryPage,
  disabled,
  getID,
  hasBarcodeError,
}: ExtendedProps) => {
  // const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(isInventoryPage ? "subtab" : "itemtab");

  const itemType = useWatch({ control, name: "type" });
  const watchBaseUom = useWatch({ control, name: "stock_uom_unique_id" });
  const watchConversion = useWatch({ control, name: "uom_conversion_list" });

  const tabs: ITab[] = [
    {
      testid: "item-tab-general",
      label: t("inventory.items.general"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=general`
        : `${pathname}?itemtab=general`,
      errors:
        errors.item_level_1_unique_id ||
        errors.physical_attribute?.length ||
        errors.physical_attribute?.width ||
        errors.physical_attribute?.thick ||
        errors.physical_attribute?.weight ||
        errors.physical_attribute?.height ||
        errors.physical_attribute?.length_uom_unique_id ||
        errors.physical_attribute?.width_uom_unique_id ||
        errors.physical_attribute?.thick_uom_unique_id ||
        errors.physical_attribute?.weight_uom_unique_id ||
        errors.physical_attribute?.height_uom_unique_id,
    },
    {
      testid: "item-tab-warehouse",
      label: t("inventory.warehouse"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=warehouse`
        : `${pathname}?itemtab=warehouse`,
      errors:
        errors.tracability ||
        errors.barcode ||
        errors.purchase_uom_unique_id ||
        errors.deliver_uom_unique_id ||
        errors.sales_uom_unique_id ||
        errors.stock_uom_unique_id ||
        hasBarcodeError,
    },
    {
      testid: "item-tab-logistic",
      label: t("inventory.items.logistic"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=logistic`
        : `${pathname}?itemtab=logistic`,
    },
    {
      testid: "item-tab-purchase",
      label: t("inventory.items.purchase"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=purchase`
        : `${pathname}?itemtab=purchase`,
      errors:
        errors.purchase_standard_price ||
        errors.purchase_minimum_qty ||
        errors.purchase_vat_type,
    },
    {
      testid: "item-tab-sales",
      label: t("inventory.items.sales"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=sales`
        : `${pathname}?itemtab=sales`,
      errors: errors.sales_price || errors.sales_vat_type,
    },
    {
      testid: "item-tab-manufacture",
      label: t("inventory.items.manufacture"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=manufacture`
        : `${pathname}?itemtab=manufacture`,
    },
    {
      testid: "item-tab-mrp",
      label: t("inventory.items.mrp"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=mrp`
        : `${pathname}?itemtab=mrp`,
    },
    {
      testid: "item-tab-specific",
      label: t("inventory.items.specificInformation"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=specific`
        : `${pathname}?itemtab=specific`,
      errors: errors.attribute_list,
    },
    {
      testid: "item-tab-add-stock",
      label: t("inventory.items.stock"),
      path: isInventoryPage
        ? `${pathname}?tab=item&subtab=stock`
        : `${pathname}?itemtab=stock`,
    },
  ];

  const currentTab =
    pathname +
    (tab
      ? isInventoryPage
        ? `?tab=item&subtab=${tab}`
        : `?itemtab=${tab}`
      : isInventoryPage
      ? "?tab=item&subtab=general"
      : "?itemtab=general");

  const renderTab = () => {
    switch (tab) {
      case "general":
        return (
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            itemType={itemType}
            disabled={disabled}
            allUoms={allUoms}
          />
        );
      case "logistic":
        return (
          <Logistic
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            allUoms={allUoms}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      case "warehouse":
        return (
          <Warehouse
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            watchBaseUom={watchBaseUom}
            watchConversion={watchConversion}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
            hasBarcodeError={hasBarcodeError}
          />
        );
      case "sales":
        return (
          <Sales
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      case "purchase":
        return (
          <Purchase
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      case "specific":
        return (
          <SpecificInfo
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      case "mrp":
        return (
          <MRP
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      case "manufacture":
        return (
          <Manufacture
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      case "stock":
        return (
          <Stock
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
          />
        );
      default:
        return (
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            isInventoryPage={isInventoryPage}
            disabled={disabled}
            itemType={itemType}
            allUoms={allUoms}
          />
        );
    }
  };

  const subTabsOptions = () => {
    if (!getValues("is_stockable")) tabs.splice(8, 1);
    if (["normal", "variation"].includes(itemType)) return tabs;
    else if (itemType === "bundle") {
      tabs.splice(2, 1);
      tabs.splice(4, 2);
      return tabs;
    } else if (itemType === "service")
      return [tabs[0], tabs[1], tabs[3], tabs[4], tabs[7]];
    else return tabs;
  };

  const renderImgComponent = () => {
    if (["normal", "bundle"].includes(itemType))
      return (
        <ItemImg
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
        />
      );
    else return null;
  };

  return (
    <Fragment>
      <CustomizedBox boxShadow={!isInventoryPage ? "none" : undefined}>
        <Information
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          disabled={disabled}
          isInventoryPage={isInventoryPage}
          getID={getID}
        />
      </CustomizedBox>
      {renderImgComponent()}
      {!isInventoryPage && <Divider />}
      <CustomizedBox boxShadow={!isInventoryPage ? "none" : undefined}>
        <CustomizedScrollbar>
          <CustomizedTab
            subtab
            tabs={subTabsOptions()}
            currentTab={currentTab}
          />
        </CustomizedScrollbar>
        <CustomizedScrollbar>{renderTab()}</CustomizedScrollbar>
      </CustomizedBox>
    </Fragment>
  );
};

export default DetailTab;
