import { Box, Button, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CustomizedScrollbar from "../../../Custom/CustomizedScrollbar";
import { ReactInstance, ReactNode, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PDFHeader from "./PDFHeader";
import OldPDFSigner from "./PDFSigner";
import PDFFooter from "./PDFFooter";

interface Props {
  children?: ReactNode;
  documentName: string;
  data: any;
  documentType?: string;
  noCompany?: boolean;
}

const PDFLayout = ({
  children,
  documentName,
  data,
  documentType,
  noCompany,
}: Props) => {
  const { id } = useParams();

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">{documentName}</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button size="small" variant="outlined" onClick={handlePrint}>
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>

      <Box sx={{ m: "10px auto", width: "fit-content" }}>
        <Box
          sx={{
            width: "210mm",
            height: "297mm",
            border: "1px solid #eee",
            borderRadius: "5px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <CustomizedScrollbar
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 3,
              }}
              ref={componentRef}
            >
              <Box>
                <PDFHeader documentName={documentName} noCompany={noCompany} />
                <Divider sx={{ my: 2 }} />
                {children}
                <PDFFooter data={data} documentType={documentType} />
                <Divider sx={{ my: 2 }} />
              </Box>
              <Box
                sx={{
                  py: 3,
                  breakInside: "avoid",
                }}
              >
                <OldPDFSigner documentType={documentType} />
              </Box>
            </Box>
          </CustomizedScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default PDFLayout;
