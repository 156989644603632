import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import PurchaseOrderHeader from "../../../components/Form/Purchase/Order/Header";
import VendorInfoForm from "../../../components/Form/VendorInfo/VendorInfoForm";
import PurchaseItemList from "../../../components/Table/Purchase/ItemList/PurchaseItemList";
import PurchaseFooter from "../../../components/Form/Purchase/Footer";
import {
  WarehousesQuery,
  useWarehousesQuery,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { Box, CircularProgress } from "@mui/material";
import {
  purchaseOrderSchema,
  purchaseOrderValidation,
} from "../../../components/Form/Purchase/Order/schema";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import {
  purchaseOrderCreatePayloadFormatter,
  purchaseOrderQueryFormatter,
  purchaseOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/PurchaseOrder";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PurchaseDocumentType,
  PurchaseOrderCreateInput,
  PurchaseOrderQuery,
  PurchaseOrderUpdateInput,
  usePurchaseDocumentNextStatusMutation,
  usePurchaseOrderCreateMutation,
  usePurchaseOrderQuery,
  usePurchaseOrderUpdateMutation,
} from "../../../generated/purchase";
import { useSnackbar } from "notistack";
import { errorMessageFormatter } from "../../Setting/Inventory/Warehouse";
import PriceVatType from "../../../components/Form/Purchase/PriceVatType";
import { EntityTypeEnum } from "../../../generated/creatable";
import { usePurchaseError } from "../../../hooks/Purchase/use-purchase-error";
import { GraphQLClient } from "graphql-request";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    resetField,
  } = useForm<IPurchaseOrder>({
    defaultValues: purchaseOrderSchema,
    resolver: yupResolver(purchaseOrderValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseOrderQuery<PurchaseOrderQuery>(
      graphQLClientWithHeaderPurchase,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutate: create } =
    usePurchaseOrderCreateMutation<Error>(graphQLClientWithHeaderPurchase, {
      onSuccess: ({ purchaseOrderCreate }) => {
        if (purchaseOrderCreate) {
          if (purchaseOrderCreate.sub_status === "wait_approve") {
            updateStatus({
              documentInput: {
                reference_document_type: PurchaseDocumentType.PurchaseOrder,
                unique_id: purchaseOrderCreate.unique_id,
              },
            });
          }
          navigate(`/purchase/order/${purchaseOrderCreate?.unique_id}`);
          enqueueSnackbar("สร้างใบสั่งซื้อสำเร็จ", {
            variant: "success",
          });
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบสั่งซื้อไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutate: update } =
    usePurchaseOrderUpdateMutation<Error>(graphQLClientWithHeaderPurchase, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}ใบสั่งซื้อสำเร็จ`, {
          variant: "success",
        });
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`${snackbarMessage}ใบสั่งซื้อไม่สำเร็จ`, {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isChanging, mutate: updateStatus } =
    usePurchaseDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderPurchase,
      {
        onSuccess: () => {
          if (id) {
            refetch();
          }
        },
      }
    );

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  useEffect(() => {
    if (isSuccess) {
      const { purchaseOrder } = data;

      const getPurchaseOrderData = async () => {
        const purchaseOrderType = purchaseOrder as IPurchaseOrder;
        const formattedPurchaseOrder = await purchaseOrderQueryFormatter(
          purchaseOrderType
        );
        reset(formattedPurchaseOrder);
      };
      getPurchaseOrderData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [reset, state]);

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.purchaseOrder?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.purchaseOrder?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onPurchaseOrderCreate = (data: IPurchaseOrder, status: string) => {
    if (data) {
      const payload = purchaseOrderCreatePayloadFormatter(
        data,
        status
      ) as PurchaseOrderCreateInput;
      create({
        createInput: payload,
      });
    }
  };

  const onPurchaseOrderUpdate = (data: IPurchaseOrder, status: string) => {
    if (data) {
      const payload = purchaseOrderUpdatePayloadFormatter(
        data,
        status
      ) as PurchaseOrderUpdateInput;
      setSnackbarMessage("แก้ไข");
      update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onPurchaseOrderSendApprove = (data: IPurchaseOrder) => {
    if (data) {
      const payload = purchaseOrderUpdatePayloadFormatter(
        data,
        "wait_approve"
      ) as PurchaseOrderUpdateInput;

      if (!id) {
        onPurchaseOrderCreate(data, "wait_approve");
      } else {
        setSnackbarMessage("ส่งอนุมัติ");
        update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        updateStatus({
          documentInput: {
            reference_document_type: PurchaseDocumentType.PurchaseOrder,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onPurchaseOrderNotApprove = (data: IPurchaseOrder) => {
    const payload = purchaseOrderUpdatePayloadFormatter(
      data,
      "wait_approve",
      true
    ) as PurchaseOrderUpdateInput;
    setSnackbarMessage("ไม่อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
  };

  const onPurchaseOrderApprove = (data: IPurchaseOrder) => {
    const payload = purchaseOrderUpdatePayloadFormatter(
      data,
      "approved"
    ) as PurchaseOrderUpdateInput;
    setSnackbarMessage("อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
    updateStatus({
      documentInput: {
        reference_document_type: PurchaseDocumentType.PurchaseOrder,
        unique_id: data.unique_id,
      },
    });
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onPurchaseOrderEditHandler = (data: IPurchaseOrder) => {
    setDisabled(true);
    setIsEdit(false);
    setSnackbarMessage("แก้ไข");
    onPurchaseOrderUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const renderButton = () => {
    switch (data?.purchaseOrder?.aggrid_status) {
      case "draft":
        return (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onPurchaseOrderUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseOrderSendApprove)}
              disabled={isChanging}
            />
          </Box>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderEditHandler)}
              />
            </Box>
          );
        } else {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.not_approve")}
                disabled={isUpdating}
                onClick={handleSubmit(onPurchaseOrderNotApprove)}
              />
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderApprove)}
                disabled={isChanging}
              />
            </Box>
          );
        }

      case "not_approved":
      case "approved":
      case "cancelled":
      case "partially_imported":
      case "fully_imported":
        if (isEdit) {
          return (
            <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderEditHandler)}
              />
            </Box>
          );
        }
        return;
      default:
        return (
          <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onPurchaseOrderCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseOrderSendApprove)}
            />
          </Box>
        );
    }
  };

  usePurchaseError(errors);

  if ((id && (isLoading || isUpdating)) || isWarehouseLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <PurchaseOrderHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        allWarehouses={warehouseData?.warehouses || []}
        editClickHandler={editClickHandler}
        refetch={refetch}
        update={update}
        handleSubmit={handleSubmit}
      />
      <VendorInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.PurchaseOrder}
      />
      <PriceVatType control={control} errors={errors} disabled={disabled} />
      <PurchaseItemList
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        documentType={EntityTypeEnum.PurchaseOrder}
      />
      <PurchaseFooter
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        documentType="purchase_order"
        resetField={resetField}
      />
      {renderButton()}
    </form>
  );
};

export default DocumentInfoTab;
