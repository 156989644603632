import { useParams } from "react-router-dom";
import { GraphQLClient } from "graphql-request";

import { Box, CircularProgress, Divider } from "@mui/material";

import { formatDate } from "../../../utils/Formatter/Date";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  DeliveryOrderQuery,
  useDeliveryOrderQuery,
} from "../../../generated/logistic";
import DeliveryOrderPdfTable from "../../../components/Table/Pdf/DeliveryOrderPdfTable";
import { IDeliveryOrder } from "../../../types/Logistic/deliveryOrder";
import PDFInfo from "../../../components/UI/Pdf/OldPDF/PDFInfo";
import PDFLayout from "../../../components/UI/Pdf/OldPDF/PDFLayout";
import { formatTaxId } from "../../../utils/Formatter/Global";

const DeliveryOrderPDF = () => {
  const { id } = useParams();

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");

  const { data, isLoading } = useDeliveryOrderQuery<DeliveryOrderQuery>(
    graphQLClientWithHeaderLogistic,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const renderAddressString = (addressValues: any) => {
    if (addressValues) {
      const {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = addressValues;

      const orderedValues = {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            const newValue = orderedValues[key as keyof typeof orderedValues];
            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "";
  };

  const leftHeader = [
    "รหัสลูกค้า / Customer ID",
    "ชื่อลูกค้า / Customer Name",
    "ที่อยู่จดทะเบียน / Billing Address",
    "เลขผู้เสียภาษี / Tax ID",
    "เบอร์โทรศัพท์ / Phone",
    "แฟกซ์ / Fax",
    "อีเมล / Email",
  ];

  const leftInfo = [
    data?.deliveryOrder?.customer_contact_unique_id || "-",
    data?.deliveryOrder?.customer_contact?.name || "-",
    renderAddressString(
      data?.deliveryOrder?.customer_contact?.billing_address
    ) || "-",
    formatTaxId(
      data?.deliveryOrder?.customer_contact?.identity_no || "",
      data?.deliveryOrder?.customer_contact?.branch || ""
    ) || "-",
    data?.deliveryOrder?.customer_contact?.phone || "-",
    data?.deliveryOrder?.customer_contact?.fax || "-",
    data?.deliveryOrder?.customer_contact?.email || "-",
  ];

  const rightHeader = [
    "เลขที่เอกสาร / Document No.",
    "อ้างอิงถึง / Ref. Document",
    "วันที่ออกเอกสาร / Issue date",
    "วันที่ส่งของ / Delivery Date",
    "ที่อยู่จัดส่ง / Delivery Address",
  ];

  const rightInfo = [
    data?.deliveryOrder?.unique_id || "-",
    data?.deliveryOrder?.reference_unique_id_list &&
    data?.deliveryOrder?.reference_unique_id_list.length > 0
      ? data?.deliveryOrder?.reference_unique_id_list.join(", ")
      : "-",
    formatDate(data?.deliveryOrder?.issue_date) || "-",
    formatDate(data?.deliveryOrder?.delivery_date) || "-",
    renderAddressString(
      data?.deliveryOrder?.customer_contact?.delivery_address || {}
    ) || "",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบส่งของ/Delivery Order"
      data={data?.deliveryOrder}
      documentType="delivery_order"
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <DeliveryOrderPdfTable data={data?.deliveryOrder as IDeliveryOrder} />
    </PDFLayout>
  );
};

export default DeliveryOrderPDF;
