import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress, Divider } from "@mui/material";

import IngredientPdfTable from "../../../components/Table/Pdf/IngredientPdfTable";

import { formatDate } from "../../../utils/Formatter/Date";
import { BomQuery, useBomQuery } from "../../../generated/manufacture";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IIngredient } from "../../../types/Manufacture";
import PDFInfo from "../../../components/UI/Pdf/OldPDF/PDFInfo";
import PDFLayout from "../../../components/UI/Pdf/OldPDF/PDFLayout";

const BomPDF = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data, isLoading } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { id: parseInt(id ?? "0") },
    },
    { enabled: !!id }
  );

  const leftHeader = [
    t("manufacture.bom.bom_name"),
    t("manufacture.bom.bom_description"),
    t("manufacture.bom.item_unique_id"),
    t("manufacture.bom.item_name"),
    t("manufacture.bom.mfg_qty"),
    t("manufacture.bom.uom"),
    t("manufacture.bom.type"),
  ];

  const leftInfo = [
    data?.bom?.name || "-",
    data?.bom?.description || "-",
    data?.bom?.item_unique_id || "-",
    data?.bom?.item_name || "-",
    data?.bom?.mfg_qty || "-",
    data?.bom?.uom || "-",
    data?.bom?.mfg_type || "-",
  ];

  const rightHeader = [
    t("manufacture.bom.created_date"),
    t("manufacture.bom.start_date"),
    t("manufacture.bom.end_date"),
  ];

  const rightInfo = [
    formatDate(data?.bom?.created_date) || "-",
    formatDate(data?.bom?.start_date) || "-",
    formatDate(data?.bom?.end_date) || "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="สูตรการผลิต/BOM"
      data={data?.bom}
      documentType="bom"
      noCompany
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <IngredientPdfTable
        data={(data?.bom?.ingredient_list as IIngredient[]) || []}
        isBom
      />
    </PDFLayout>
  );
};

export default BomPDF;
