import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import DocDropzoneUI from "../../../UI/DocDropzoneUI";

import { useDisable } from "../../../../hooks/use-disable";

const RoutingAttachment = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [disabled] = useDisable();

  return (
    <CustomizedBox>
      <Typography ml={1} mb={3} fontWeight="bold">
        {t("sentence.attachment_list")}
      </Typography>
      <DocDropzoneUI
        control={control}
        name="attachment_list"
        setValue={setValue}
        disabled={disabled}
        maxSize={10}
        multiple
      />
    </CustomizedBox>
  );
};

export default RoutingAttachment;
